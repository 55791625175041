import { SMALL } from "constants/breakpoints";
import { useMediaQuery } from "react-responsive";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Text from "components/Text";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import Divider from "@mui/material/Divider";
import OrderDetails from "components/Orders/OrderDetails/OrderDetails";

import CheckIcon from "@mui/icons-material/Check";
import HelpIcon from "@mui/icons-material/Help";
import { Box, FormGroup, Stack } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { QUERY, payCustomerOrder } from "api";
import queryClient from "api/queryClient";
import Button from "components/Buttons/Button";
import { TruckIcon } from "components/Icons";
import OrderStatusTag from "components/Labels/OrderStatusTag";
import ChangePlanModal from "components/Modals/ChangePlanModal";
import ConfirmOrderModal from "components/Modals/ConfirmOrderModal";
import DunningModal from "components/Modals/DunningModal";
import OrderWithIssuesModal from "components/Modals/OrderWithIssuesModal";
import PaywallUpgradeModal from "components/Modals/PaywallUpgradeModal";
import {
  GenericSuccessNotification,
  NoBillingMethodError,
} from "components/Notifications";

import {
  FEES_CONSTANT,
  FEES_PERCENTAGE,
  ORDER_STATUS_PAYMENT_REQUIRED,
  PRODUCT_TYPE_UNBRANDED,
} from "constants/constants";
import { useProfile } from "hooks";
import useCustomerOrders from "hooks/useCustomerOrders";
import moment from "moment";
import CheckoutConfirmPayment from "views/CheckoutPaymentView/components/CheckoutConfirmPayment";

import OrderStatusBar from "components/Orders/OrderStatusBar/OrderStatusBar";
import useChangePlanModal from "hooks/useChangePlanModal";
import cn from "utils/cn";
import CustomerOrderItem from "../CustomerOrderItem";
import styles from "./NewCustomerOrdersCard.module.scss";

const UNFULFILLED = "UNFULFILLED";
const PAYMENT_REQUIRED = "PAYMENT_REQUIRED";

const stripePromise = loadStripe(String(process.env.REACT_APP_STRIPE_KEY));

function NewCustomerOrderCard(props) {
  const { order, onPlaceOrder, isReadOnly, refetch } = props;

  const isMobile = useMediaQuery({ maxWidth: SMALL });

  const profileHook = useProfile();
  const [clientSecret, setClientSecret] = useState(null);
  const [nextActionType, setNextActionType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openDunningModal, setOpenDunningModal] = useState(false);
  const [openPaywallUpgradeModal, setOpenPaywallUpgradeModal] = useState(false);
  const [confirmation, setConfirmation] = useState(false);

  const [brandedBoxItems, setBrandedBoxItems] = useState([]);
  const [fullfilledByBlanka, setFilledByBlanka] = useState(
    order.is_fulfillable_with_stored_merchant_inventory ?? false
  );
  const shop = useSelector((state) => state.profile.shop);
  const payOrderMutation = useMutation(payCustomerOrder);

  const [shipping, setShipping] = useState();
  const [subtotal, setSubtotal] = useState(order.order_sub_total || 0);
  const [fees, setFees] = useState();
  // const [total, setTotal] = useState();

  const { onOpenModal } = useChangePlanModal();

  const navigate = useNavigate();
  const [getBrandedBoxFees, getBrandedBoxData, getPickNPackFee] =
    useCustomerOrders();

  useEffect(() => {
    if (fullfilledByBlanka) {
      setSubtotal(0);
      setFees(shipping * FEES_PERCENTAGE + FEES_CONSTANT);
    } else {
      setSubtotal(order.order_sub_total);
      setShipping(order.order_shipping_total);
      setFees(order.order_fees);
    }
  }, [fullfilledByBlanka, order, shipping]);

  const [displayModalAlert, setDisplayModalAlert] = useState(false);
  const [openOrderWithIssuesModal, setOpenOrderWithIssuesModal] =
    useState(displayModalAlert);

  const handleAccept = () => {
    setOpenOrderWithIssuesModal(false);
    setDisplayModalAlert(false);
    setConfirmation(true);
  };

  useEffect(() => {
    setSubtotal(order.order_sub_total);
    setShipping(order.order_shipping_total);
    setFees(order.order_fees);
  }, [brandedBoxItems]);

  useEffect(() => {
    if (fullfilledByBlanka) {
      setSubtotal(0);
      setFees(shipping * FEES_PERCENTAGE + FEES_CONSTANT);
    } else {
      setSubtotal(order.order_sub_total);
      setShipping(order.order_shipping_total);
      setFees(order.order_fees);
    }
  }, [fullfilledByBlanka]);

  const handlePrevious = () => {
    navigate("/orders");
  };

  const handleFulfillByBlankaChange = (selected) => {
    setFilledByBlanka(selected);
  };

  const handlePaymentSuccess = () => {
    const orderId = localStorage.getItem("lastViewedId");
    queryClient.refetchQueries([orderId, "order"]);
    refetch();
    setLoading(false);
    toast.success(
      <GenericSuccessNotification text="Your customer order has been placed" />
    );
    // queryClient.refetchQueries([QUERY.getCustomerOrders]).then(() => {
    //   setLoading(false);
    // });
  };

  const handlePlaceOrder = async (id) => {
    setConfirmation(false);
    setLoading(true);

    payOrderMutation.mutate(
      {
        orderID: id,
        boxData: [],
        useOwnInventory: fullfilledByBlanka,
      },
      {
        onSuccess: (response) => {
          if (response.data.requires_action) {
            setClientSecret(response.data.client_secret);
            setNextActionType(response.data.next_action?.type || null);
            setLoading(true);
            return;
          }

          const orderId = localStorage.getItem("lastViewedId");
          queryClient.refetchQueries([orderId, "order"]);
          refetch();
          toast.success(
            <GenericSuccessNotification text="Your customer order has been placed" />
          );
          setLoading(false);
        },
        onError: (err) => {
          setLoading(false);

          // TODO: handle specific error codes from server.
          console.log("Error", err.response.data);
          if (err.response.data.error_code === "NO_BILLING_DETAILS") {
            toast.error(<NoBillingMethodError />);
          }
        },
      }
    );
  };

  const hasBrandedBox = (brandedBox) =>
    brandedBox ? <CheckIcon className="icon--check" /> : "";

  const trackingStatus = () => {
    if (order.status === PAYMENT_REQUIRED) {
      return (
        <Text className="text--nunito text__tracking-header">PENDING</Text>
      );
    }
    if (order.status === UNFULFILLED) {
      return (
        <Text className="text--nunito text__tracking-header">
          ORDER IS BEING PROCESSSED
        </Text>
      );
    }

    return (
      <a
        target="_blank"
        href={`https://parcelsapp.com/en/tracking/${order.tracking_code}`}
        className="text__tracing-number"
        rel="noreferrer"
      >
        {order.tracking_code}
      </a>
    );
  };

  // filter out discontinued or out of stock orders for alert
  const handleFilterOrders = (rawOrders) => {
    const requirePaymentOrders = rawOrders.filter(
      (order) => order.status === ORDER_STATUS_PAYMENT_REQUIRED
    );

    const filtered = requirePaymentOrders.filter((order) =>
      order.items.some(
        (item) =>
          item.product?.product_base?.is_discontinued ||
          item.product?.product_base?.out_of_stock
      )
    );

    setDisplayModalAlert(filtered.length > 0);
  };

  const checkPlanLimits = () => {
    if (profileHook.isOverLimit()) {
      setOpenPaywallUpgradeModal(true);
      setLoading(false);
      return;
    }
    if (!profileHook.isActiveOrTrial()) {
      setOpenDunningModal(true);
      return;
    }

    if (displayModalAlert) {
      setOpenOrderWithIssuesModal(true);
      setConfirmation(false);
      setLoading(false);
    }

    setConfirmation(true);
  };

  useEffect(() => {
    handleFilterOrders([order]);
  }, [order]);

  return (
    <Card className="order-card root">
      <CardContent className={styles.cardContent}>
        {!isMobile && (
          <div className="order-card__header">
            <div onClick={handlePrevious}>
              <div className={styles.backBtn}>
                <KeyboardBackspaceIcon />
                Back To Orders
              </div>
            </div>
          </div>
        )}

        <div className="order-card__details">
          {!isMobile ? (
            <>
              <Box className={styles.appendedHeader}>
                <Stack direction="row" gap="32px">
                  <Box
                    className="text--bold"
                    align="left"
                    classes={styles.cell}
                  >
                    <Text variant="body2" className={styles.title}>
                      Date
                    </Text>
                    <Text variant="body2" className={styles.peachText}>
                      {moment(order?.created_at).format("MMM DD, YYYY")}
                    </Text>
                  </Box>
                  <Box
                    className="text--bold"
                    align="left"
                    classes={styles.cell}
                  >
                    <Text variant="body2" className={styles.title}>
                      Order #
                    </Text>
                    <Text variant="body2" className={styles.peachText}>
                      {order?.external_order_number}
                    </Text>
                  </Box>
                  <Box
                    className="text--bold"
                    align="left"
                    classes={styles.cell}
                  >
                    <Text variant="body2" className={styles.title}>
                      Qty
                    </Text>
                    <Text variant="body2" className={styles.peachText}>
                      {order?.number_of_items}
                    </Text>
                  </Box>
                  <Box
                    className="text--bold"
                    align="left"
                    classes={styles.cell}
                  >
                    <Text variant="body2" className={styles.title}>
                      Total
                    </Text>
                    <Text variant="body2" className={styles.peachText}>
                      ${order?.order_total}
                    </Text>
                  </Box>
                </Stack>
                <Box className="text--bold" align="center">
                  <OrderStatusBar
                    status={order.status}
                    type={order.order_type}
                  />
                </Box>
              </Box>
              <TableContainer>
                <Table aria-label="">
                  <TableHead>
                    <TableRow>
                      <TableCell className="text--bold" align="left" />
                      <TableCell className="text--bold" align="left">
                        SKU
                      </TableCell>
                      <TableCell className="text--bold" align="left">
                        Product
                      </TableCell>

                      <TableCell className="text--bold" align="center">
                        Unit Cost
                      </TableCell>
                      <TableCell className="text--bold" align="center">
                        Quantity
                      </TableCell>
                      <TableCell className="text--bold" align="center">
                        Total
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {order?.items?.map((item) => (
                      <CustomerOrderItem
                        key={item.sku}
                        item={item}
                        order={order}
                        fullfilledByBlanka={!fullfilledByBlanka}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <>
              <Box className={styles.appendedHeader}>
                <Stack direction="row" gap="32px" alignSelf="flex-start">
                  <Box
                    className="text--bold"
                    align="left"
                    classes={styles.cell}
                  >
                    <Text variant="body2" className={styles.title}>
                      Date
                    </Text>
                    <Text variant="body2" className={styles.peachText}>
                      {moment(order?.created_at).format("MMM DD, YYYY")}
                    </Text>
                  </Box>
                  <Box
                    className="text--bold"
                    align="left"
                    classes={styles.cell}
                  >
                    <Text variant="body2" className={styles.title}>
                      Order #
                    </Text>
                    <Text variant="body2" className={styles.peachText}>
                      {order?.external_order_number}
                    </Text>
                  </Box>
                  <Box
                    className="text--bold"
                    align="left"
                    classes={styles.cell}
                  >
                    <Text variant="body2" className={styles.title}>
                      Qty
                    </Text>
                    <Text variant="body2" className={styles.peachText}>
                      {order?.number_of_items}
                    </Text>
                  </Box>
                  <Box
                    className="text--bold"
                    align="left"
                    classes={styles.cell}
                  >
                    <Text variant="body2" className={styles.title}>
                      Total
                    </Text>
                    <Text variant="body2" className={styles.peachText}>
                      ${order?.order_total}
                    </Text>
                  </Box>
                </Stack>
                <Box className="text--bold" align="center">
                  <OrderStatusBar
                    status={order.status}
                    type={order.order_type}
                  />
                </Box>
              </Box>
              <Divider className={styles.divider} />
              <OrderDetails order={order} />
            </>
          )}
        </div>
      </CardContent>

      <div className="tracking-number-container">
        {!isMobile && (
          <div className={styles.trackingContainer}>
            <TruckIcon className="icon--truck" />
            <Text className="text__tracking-header text--upper text--nunito">
              Tracking Info:
            </Text>
            {trackingStatus()}
          </div>
        )}

        {!isMobile && (
          <div className="fulfill-inventory-container pb-10">
            {order.status === PAYMENT_REQUIRED ? (
              <>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <a
                  target="__blank"
                  href="https://faq.blankabrand.com/en/articles/6524252-what-does-it-mean-to-fulfil-using-my-inventory-at-blanka"
                >
                  <HelpIcon className={styles.helpIcon} />
                </a>
                <FormGroup className="fulfill-form">
                  <FormControlLabel
                    className={cn(
                      (isReadOnly ||
                        !order.is_fulfillable_with_stored_merchant_inventory) &&
                        styles.disabled
                    )}
                    control={
                      <Checkbox
                        disabled={
                          isReadOnly ||
                          !order.is_fulfillable_with_stored_merchant_inventory
                        }
                        checked={fullfilledByBlanka}
                        onChange={(event) => {
                          // setFilledByBlanka(event.target.checked);
                          // handleSelectAll(!event.target.checked);
                          handleFulfillByBlankaChange(event.target.checked);
                        }}
                        size="small"
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="FULFILL USING MY INVENTORY AT BLANKA"
                  />
                </FormGroup>
              </>
            ) : (
              <Text
                className={`${
                  !order.use_own_inventory || isMobile ? "hidden" : ""
                } text--label`}
              >
                {hasBrandedBox(order.use_own_inventory)} FULFILL USING MY
                INVENTORY AT BLANKA
              </Text>
            )}
          </div>
        )}
      </div>

      {!isMobile && (
        <div className="totals-container">
          <div className="totals-container__values-container">
            <Text className="text__totals text--nunito ">Subtotal</Text>
            <Text className="text__totals text--bold text--nunito">
              ${Number(subtotal).toFixed(2)}
            </Text>
          </div>

          {shop?.has_pick_n_pack ||
            (order?.pick_n_pack_fee > 0 && (
              <div className="totals-container__values-container">
                <Text className="text__totals text--nunito ">
                  Pick & pack Fee
                </Text>
                <Text className="text__totals text--bold text--nunito">
                  ${Number(order.pick_n_pack_fee).toFixed(2)}
                </Text>
              </div>
            ))}

          <div className="totals-container__values-container">
            <Text className="text__totals text--nunito ">Shipping</Text>
            <Text className="text__totals text--bold text--nunito">
              ${Number(shipping).toFixed(2)}
            </Text>
          </div>
          <div className="pb-10 totals-container__values-container">
            <Text className="text__totals text--nunito ">Processing Fee</Text>
            <Text className="text__totals text--bold text--nunito">
              ${Number(fees).toFixed(2)}
            </Text>
          </div>

          <div className="totals-container__values-container totals-container--border-top">
            <Text className="text__totals text--nunito text--left">Total</Text>
            <Text className="text__totals text--bold text--nunito">
              $
              {(
                Number(subtotal) +
                Number(shipping) +
                Number(fees) +
                Number(order.pick_n_pack_fee)
              ).toFixed(2)}
            </Text>
          </div>
        </div>
      )}
      {!isMobile && (
        <CardActions disableSpacing>
          {order.status === PAYMENT_REQUIRED && (
            <Button
              loading={loading}
              disabled={order.has_error}
              onClick={() => checkPlanLimits()}
              variant="contained"
              className="button--primary btn-submit-order"
              color="primary"
            >
              Place Order <i className="fas fa-chevron-right" />
            </Button>
          )}

          {order.has_error && (
            <Text className="text--nunito text--error text__order-error">
              Oops! There is an issue with the shipping address on this order.
              Please contact us to resolve this.
            </Text>
          )}
        </CardActions>
      )}

      <Elements stripe={stripePromise}>
        <CheckoutConfirmPayment
          nextActionType={nextActionType}
          clientSecret={clientSecret}
          handlePaymentSuccess={handlePaymentSuccess}
          handlePaymentError={() => {
            setNextActionType();
            setClientSecret();
            setLoading(false);
          }}
        />
      </Elements>

      <OrderWithIssuesModal
        open={openOrderWithIssuesModal}
        onClose={() => setOpenOrderWithIssuesModal(false)}
        onAccept={() => handleAccept()}
      />

      <PaywallUpgradeModal
        open={openPaywallUpgradeModal}
        handleClose={() => setOpenPaywallUpgradeModal(false)}
        planId={shop.plan}
        handlePlanChange={() => onOpenModal()}
      />

      <DunningModal
        open={openDunningModal}
        allowClose={false}
        handleClose={() => setOpenDunningModal(false)}
      />
      <ConfirmOrderModal
        open={confirmation && !displayModalAlert}
        handleClose={() => setConfirmation(false)}
        onConfirm={() => handlePlaceOrder(order.id)}
        price={(
          Number(subtotal) +
          Number(shipping) +
          Number(fees) +
          Number(order.pick_n_pack_fee)
        ).toFixed(2)}
      />
    </Card>
  );
}

export default NewCustomerOrderCard;
