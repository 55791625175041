import PropTypes from "prop-types";
import React, { useEffect } from "react";

import Text from "components/Text";

import OrderTable from "components/Tables/OrderTable";

import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import { Box, Divider } from "@mui/material";
import moment from "moment";

import {
  ORDER_STATUS_CANCELLED,
  ORDER_STATUS_FULFILLED,
  ORDER_STATUS_PAYMENT_REQUIRED,
  ORDER_STATUS_SHIPPED,
  ORDER_STATUS_UNFULFILLED,
} from "constants/constants";

import styles from "./OrderDetails.module.scss";

export default function OrderDetails({ order }) {
  const {
    status,
    tracking_code: trackingCode,
    external_order_number: externalOrderNumber,
    order_type: orderType,
  } = order;

  const getTrackingCode = (status) => {
    switch (status) {
      case ORDER_STATUS_PAYMENT_REQUIRED:
        return <Text className={styles.trackPaymentRequired}>Pending</Text>;
      case ORDER_STATUS_UNFULFILLED:
        return (
          <Text className={styles.trackUnfulfilled}>
            Order is being processed
          </Text>
        );
      case ORDER_STATUS_SHIPPED:
        return <Text className={styles.trackFulfilled}>{trackingCode}</Text>;
      case ORDER_STATUS_FULFILLED:
        return <Text className={styles.trackFulfilled}>{trackingCode}</Text>;
      case ORDER_STATUS_CANCELLED:
        return <Text className={styles.trackFulfilled}>N/A</Text>;
      default:
        return <Text />;
    }
  };

  return (
    <Box className={styles.orderDetails}>
      <Box className={styles.trackContainer}>
        <div>
          <LocalShippingOutlinedIcon />
          <Text className={styles.trackInfo}>Tracking info</Text>
        </div>
        {getTrackingCode(status)}
      </Box>
      <Divider orientation="horizontal" className={styles.divider} />
      <OrderTable order={order} />
    </Box>
  );
}
