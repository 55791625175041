import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CART_TYPE_SAMPLE,
  CUSTOM_PRODUCT_STATUS_COMPLETE,
  DEBOUNCE_DELAY,
} from "constants/constants";

import useCart from "hooks/useCart";
import {
  clearCartDiscount,
  createCartAsyncThunk,
  getTiersByProductIdAsync,
  setCart,
  setSampleOrderDiscount,
  setShippingCart,
  toggleBrandedBoxForAllItems,
  updateCart,
} from "state/cartSlice";

import { SMALL } from "constants/breakpoints";

import PageHeader from "components/PageHeader";
import { useMediaQuery } from "react-responsive";
import CartProductStatusModal from "components/Modals/CartProductStatusModal";
import UnavailableProductNotification from "components/Notifications/UnavailableProductNotification/UnavailableProductNotification";
import CartDesktopView from "./components/CartDesktopView/CartDesktopView";
import CartMobileView from "./components/CartMobileView/CartMobileView";

import ConfirmUnbrandedOrderModal from "../../components/Modals/ConfirmUnbrandedOrderModal";
import CartEmpty from "./components/CartEmpty";

import "styles/components/breadcrumbs.scss";
import styles from "./CartView.module.scss";

function CartView(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [isCartProductStatusOpen, setIsCartProductStatusOpen] = useState(false);
  const [
    unavailableProductsNotificationOpen,
    setUnavailableProductsNotificationOpen,
  ] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cartHook = useCart();

  const cart = useSelector((state) => state.cart);
  const shop = useSelector((state) => state.profile.shop);

  // check if cart has any items that are discontinued or out of stock
  useEffect(() => {
    if (
      cartHook.hasDiscontinuedOrOutOfStock(cart.items) &&
      !unavailableProductsNotificationOpen
    ) {
      setUnavailableProductsNotificationOpen(true);
    }
  }, [cart.items]);

  const isMobile = useMediaQuery({ maxWidth: SMALL });

  const handleContinue = () => {
    navigate("/checkout/shipping");
  };

  const onConfirm = () => {
    setIsOpen(false);
    handleContinue();
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const handleUpdate = async (item, quantity) => {
    try {
      // Convert the empty string to a suitable value
      let parsedQuantity = quantity === "" ? 0 : parseInt(quantity, 10);

      // Check if the parsedQuantity is NaN (which occurs if the input is not a number)
      // If so, default to a value such as 0 or null
      if (Number.isNaN(parsedQuantity)) {
        parsedQuantity = 0;
      }

      cartHook.updateQuantity(item, parsedQuantity);

      // If the debounced function needs to know the quantity, pass it as an argument,
      // otherwise, you can call it without arguments
      cartHook.debouncedSendCartToServer();
    } catch (err) {
      console.log(err);
    }
  };

  const handleOnBlurredInputField = async (item, quantity) => {
    if (quantity === "0") {
      handleUpdate(item, 1);
      return;
    }

    handleUpdate(item, quantity);
  };

  useEffect(() => {
    // Using 1 as productId only because the tiers are for all products so far
    dispatch(getTiersByProductIdAsync({ productId: 1 }));
    dispatch(
      setShippingCart({
        store_at_blanka: true,
        shipping_cost: 0,
      })
    );
  }, []);

  const handleRemoveItem = (itemId) => {
    cartHook.remove(itemId);
  };

  const handleContinueToCheckout = () => {
    if (cartHook.hasDiscontinuedOrOutOfStock(cart.items)) {
      setIsCartProductStatusOpen(true);
      return;
    }

    if (
      shop.custom_product_status === CUSTOM_PRODUCT_STATUS_COMPLETE &&
      cart.type === CART_TYPE_SAMPLE
    ) {
      setIsOpen(true);
    } else {
      handleContinue();
    }
  };

  if (cart.items.length === 0) {
    return <CartEmpty />;
  }

  return (
    <>
      {unavailableProductsNotificationOpen && (
        <UnavailableProductNotification
          handleClose={() => setUnavailableProductsNotificationOpen(false)}
        />
      )}
      <div className={`cart-template ${styles.cartView}`}>
        {!isMobile && <PageHeader title="My Shopping Cart" />}

        {!isMobile && (
          <CartDesktopView
            cart={cart}
            handleUpdate={handleUpdate}
            handleRemoveItem={handleRemoveItem}
            handleContinueToCheckout={handleContinueToCheckout}
          />
        )}

        {isMobile && (
          <CartMobileView
            cart={cart}
            handleUpdate={handleUpdate}
            handleRemoveItem={handleRemoveItem}
            handleContinueToCheckout={handleContinueToCheckout}
            handleOnBlurredInputField={handleOnBlurredInputField}
          />
        )}

        <CartProductStatusModal
          open={isCartProductStatusOpen}
          handleClose={() => setIsCartProductStatusOpen(false)}
        />

        {isOpen && (
          <ConfirmUnbrandedOrderModal
            open={isOpen}
            handleClose={onClose}
            handleConfirm={onConfirm}
          />
        )}
      </div>
    </>
  );
}

export default CartView;
