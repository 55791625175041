import {
  FEES_CONSTANT,
  FEES_PERCENTAGE,
  PICK_N_PACK_FEE,
  PRODUCT_TYPE_STORED_WITH_BLANKA,
} from "constants/constants";

function useCustomerOrders() {
  const getPickNPackFee = (items) => {
    let total = 0.0;
    items?.forEach((item) => {
      if (item.product_type === PRODUCT_TYPE_STORED_WITH_BLANKA) {
        total += Number(PICK_N_PACK_FEE) * item.quantity;
      }
    });
    return Number(total).toFixed(2);
  };

  return [getPickNPackFee];
}

export default useCustomerOrders;
