import ClearIcon from "@mui/icons-material/Clear";
import { Box, Tooltip } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import ExpiringTag from "components/Labels/ExpiringTag";
import ProductTag from "components/Labels/ProductTag";
import ProductStatusIndicator from "components/ProductStatusIndicator/ProductStatusIndicator";
import Text from "components/Text";
import {
  BLANKA_CDN_URL,
  CART_TYPE_BLANKA_LABS_INVENTORY,
  CART_TYPE_INVENTORY,
  CART_TYPE_SAMPLE,
  PRODUCT_TYPE_CUSTOM,
  VOLUME_DISCOUNTS,
} from "constants/constants";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import { ArrowUpwardOutlined, WarningAmberOutlined } from "@mui/icons-material";
import DiscountTag from "components/Labels/DiscountTag/DiscountTag";

import {
  handleVoucherSuggestions,
  selectTiersByProductId,
} from "state/cartSlice";

import styles from "./CartItem.module.scss";

/**
 * Renders a cart item in the cart view.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.item - The cart item object.
 * @param {string} props.cartType - The type of cart.
 * @param {Function} props.handleUpdate - The function to handle item update.
 * @param {Function} props.handleRemoveItem - The function to handle item removal.
 * @returns {JSX.Element} The rendered component.
 */
function CartItem({ item, cartType, handleUpdate, handleRemoveItem }) {
  const cart = useSelector((state) => state.cart);
  const tiersByProductId = useSelector(selectTiersByProductId);

  useEffect(() => {
    const updatedProduct = { ...item };

    handleUpdate(updatedProduct, item.quantity);
  }, []);

  /**
   * Returns the appropriate image for a cart item.
   * If the cart is a sample, it returns an unbranded image.
   * Assumes the image is in webp format.
   * We only want to show the unbranded versions of the products when samples are being ordered,
   * since sample orders do not have branding, exception is Blanka Labs products.
   *
   * @param {Object} item - The cart item.
   * @returns {string} The URL of the image.
   */
  const getCartImage = (item) => {
    if (cart.type === CART_TYPE_SAMPLE) {
      return `${BLANKA_CDN_URL}/unbranded/${item.sku}.webp`;
    }
    return item.product.image;
  };

  const customizedTooltip = (quantity, error) => {
    if (quantity <= 0) {
      return (
        <Box bgcolor="white" className={styles.tooltipContent}>
          <Text className={styles.tooltipTitleError}>
            Quantity cannot be 0!
          </Text>
        </Box>
      );
    }

    if (
      (cartType === CART_TYPE_INVENTORY ||
        cartType === CART_TYPE_BLANKA_LABS_INVENTORY) &&
      quantity >= 1
    ) {
      if (error) {
        return (
          <Box bgcolor="white" className={styles.tooltipContent}>
            <Text className={styles.tooltipText}>{error.message}</Text>
          </Box>
        );
      }

      return (
        <Box bgcolor="white" className={styles.tooltipContent}>
          <Text className={styles.tooltipTitle}>
            Volume discounts available!
          </Text>
          <Text className={styles.tooltipText}>Order quantities:</Text>
          <Text className={styles.tooltipText}>
            <strong>
              {VOLUME_DISCOUNTS[0].quantity} -{" "}
              {VOLUME_DISCOUNTS[1].quantity - 1}
            </strong>{" "}
            for{" "}
            <span className={styles.peach}>
              {" "}
              {VOLUME_DISCOUNTS[0].discount}% off
            </span>
          </Text>
          <Text className={styles.tooltipText}>
            <strong>
              {VOLUME_DISCOUNTS[1].quantity} -{" "}
              {VOLUME_DISCOUNTS[2].quantity - 1}
            </strong>{" "}
            for{" "}
            <span className={styles.peach}>
              {" "}
              {VOLUME_DISCOUNTS[1].discount}% off
            </span>
          </Text>
          <Text className={styles.tooltipText}>
            <strong>{VOLUME_DISCOUNTS[2].quantity}+</strong> for{" "}
            <span className={styles.peach}>
              {" "}
              {VOLUME_DISCOUNTS[2].discount}% off
            </span>
          </Text>
        </Box>
      );
    }

    return null;
  };

  const minQuantityError = (item) => {
    if (
      item.product.minimum_order_quantity > 0 &&
      item.quantity < item.product.minimum_order_quantity
    ) {
      return true;
    }
    return false;
  };

  const getEndAdornment = (item) => {
    const showInfoTooltip = cartType !== CART_TYPE_SAMPLE;

    const error =
      cart.status === "idle" && cart?.errors?.find((obj) => obj.id === item.id);
    const showErrorTooltip = cart.status === "idle" && error;

    const tooltipIconElement = (
      <InputAdornment position="end">
        {showErrorTooltip && (
          <Tooltip
            arrow
            title={customizedTooltip(item.quantity, error)}
            classes={{ tooltip: styles.customTooltip }}
          >
            <WarningAmberOutlined className={styles.tooltipError} />
          </Tooltip>
        )}
        {cartType === CART_TYPE_INVENTORY && (
          <Tooltip
            arrow
            title={customizedTooltip(item.quantity)}
            classes={{ tooltip: styles.customTooltip }}
          >
            <InfoOutlinedIcon className={styles.tooltip} />
          </Tooltip>
        )}
      </InputAdornment>
    );
    if (showInfoTooltip) {
      return tooltipIconElement;
    }
    return null;
  };
  return (
    <TableRow data-cy="cart-item">
      <TableCell align="center" className={styles.imageCell}>
        <div className={styles.productImage}>
          {item.product?.product_base?.out_of_stock &&
            !item.product?.product_base?.is_discontinued && (
              <div className={styles.diagonal} />
            )}

          <Box className={styles.statusWrapper}>
            <ProductStatusIndicator product={item.product} />
          </Box>

          <div
            className={
              item.product?.product_base?.is_discontinued &&
              styles.diagonalLines
            }
          >
            <img
              className={`image ${
                (item.product?.product_base?.out_of_stock ||
                  item.product?.product_base?.is_discontinued) &&
                styles.imageOutOfStock
              }`}
              src={getCartImage(item)}
              alt="Product"
            />
          </div>
        </div>
      </TableCell>
      <TableCell className="name-tag-cell ">
        {item.product.name}{" "}
        {item.product.color_name ? ` - ${item.product.color_name}` : ""}
        <ProductTag productType={item.product.product_type} />
      </TableCell>
      <TableCell>{item.sku || item.product.sku}</TableCell>

      <TableCell align="right" style={{ width: "100px" }} size="small">
        <FormControl fullWidth className={styles.margin}>
          <Input
            id="standard-adornment-amount"
            value={Number.isNaN(item.quantity) ? "" : item.quantity}
            type="number"
            onChange={(e) => handleUpdate(item, e.target.value)}
            startAdornment={<InputAdornment position="start" />}
            endAdornment={getEndAdornment(item)}
            error={minQuantityError(item)}
            require
          />
          {minQuantityError(item) && (
            <Text className={styles.errorText}>
              Minimum: {item.product.minimum_order_quantity}
            </Text>
          )}
        </FormControl>
      </TableCell>
      <TableCell align="right" className={styles.discountRow}>
        <Text className={styles.withDiscount}>
          ${item?.unit_cost_with_savings}{" "}
        </Text>
        {item.quantity >= VOLUME_DISCOUNTS[0].quantity &&
          cartType === CART_TYPE_INVENTORY && (
            <>
              <Text className={styles.noDiscount}>${item.product?.cost}</Text>
              {cartType === CART_TYPE_INVENTORY && (
                <Box className={styles.boxContainer}>
                  <DiscountTag
                    label={`${Number(item.discount?.value)}% off`}
                    color="primary"
                  />
                </Box>
              )}
            </>
          )}
        {tiersByProductId.status === "idle" &&
          tiersByProductId.allProducts[tiersByProductId.allProducts.length - 1]
            ?.min > item?.quantity &&
          cartType === CART_TYPE_INVENTORY && (
            <Text className={styles.withDiscountTooltip}>
              <Box className={styles.discountWarning}>
                <ArrowUpwardOutlined className={styles.icon} />{" "}
                {handleVoucherSuggestions(tiersByProductId, item)}
              </Box>
            </Text>
          )}
      </TableCell>
      <TableCell align="right">
        <Text className={styles.withDiscount}>
          ${item.total_cost_with_savings}
        </Text>

        {item.quantity >= VOLUME_DISCOUNTS[0].quantity &&
          cartType === CART_TYPE_INVENTORY && (
            <Text className={styles.noDiscount}>${item.total_cost}</Text>
          )}
      </TableCell>
      <TableCell align="right">
        <IconButton
          aria-label="delete"
          onClick={(e) => handleRemoveItem(item.id)}
          disabled={cart.status === "loading"}
        >
          <ClearIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

export default CartItem;
